<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Crear producto financiero'" />

    <!-- Dialog create financial product -->
    <v-card class="my-8 pa-1">
      <v-card-text class="pa-5">
        <v-form ref="formCreateFinancialProduct" v-model="formCreateFinancialProductValid">
          <v-text-field v-model="newFinancialProduct.name" label="Nombre" :rules="formRules.textRules" />
          <v-text-field
            v-model.number="newFinancialProduct.requirements.min_credits"
            label="Nº mínimo de créditos"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-text-field
            v-model.number="newFinancialProduct.conditions.arrear_coeficient"
            label="Coeficiente de mora"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-text-field
            v-model.number="newFinancialProduct.conditions.waiting_period"
            label="Meses de carencia"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-text-field v-model.number="newFinancialProduct.conditions.monthly_fees" label="Cuotas" :rules="formRules.numberRules" />
          <v-text-field
            v-model.number="newFinancialProduct.conditions.monthly_interest"
            label="Intereses mensuales"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-text-field
            v-model.number="newFinancialProduct.conditions.commission"
            label="Comisión"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-text-field
            v-model.number="newFinancialProduct.conditions.commission_real"
            label="Comisión real"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-select
            v-model="newFinancialProduct.type"
            :items="financialProductTypes"
            label="Tipo de producto"
            :rules="formRules.textRules"
            @change="newFinancialProduct.conditions.zero_interest_in_waiting_period = false"
          />
          <v-checkbox
            v-if="newFinancialProduct.type === 'COOPERATIVE'"
            v-model="newFinancialProduct.conditions.zero_interest_in_waiting_period"
            label="No capitalizar interes en periodo de carencia"
          />
          <DatePicker
            :parent_model="newFinancialProduct.expiration_date"
            label="Fecha de expiración"
            :min="dateNextDayFormated()"
            @change="newFinancialProduct.expiration_date = $event"
          />
          <ProductTags :tags.sync="newFinancialProduct.tags" />

          <p class="mb-2 mt-5 text-subtitle-1">Disponible en:</p>
          <v-row>
            <v-col v-show="offices.some((e) => e.country.id === country.id)" v-for="(country, i) in countries" :key="i" cols="12" md="3">
              <v-card>
                <v-toolbar color="primary" dense>
                  {{ country.name }}
                </v-toolbar>
                <v-card-text>
                  <v-treeview
                    v-if="countryItems.length > 0"
                    v-model="countriesSelected[country.id]"
                    :items="countryItems.filter((e) => e.id === country.id)"
                    open-all
                    selectable
                    @input="toogleCountry"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <div v-if="isSomeOfficeSelected" class="my-5">
            <div class="d-flex align-center mb-2">
              <p class="text-subtitle-1 mb-0 mr-3">Pagos iniciales:</p>
              <v-btn elevation="2" x-small rounded icon @click="addInitialPayment">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
            <v-row>
              <v-col v-for="(initialPayment, i) in initialPayments" :key="i" cols="12" md="6" lg="4">
                <v-btn text x-small class="float-right pt-4" @click="initialPayments.splice(i, 1)">
                  <v-icon small color="red">delete</v-icon>
                </v-btn>
                <div class="secondary pa-5 mb-2">
                  <v-text-field v-model="initialPayment.name" label="Nombre" :rules="formRules.textRules" />
                  <div v-for="(countryId, i) in _.keys(initialPayment.values)" :key="i" class="d-flex align-center">
                    <v-text-field
                      v-model.number="initialPayment.values[countryId].value"
                      type="number"
                      label="Cantidad"
                      class="mr-3"
                      :suffix="countryId"
                      :rules="formRules.numberRules"
                    />
                    <v-btn
                      x-small
                      icon
                      elevation="3"
                      class="pa-3"
                      :class="{ primary: initialPayment.values[countryId].type === 'percentage' }"
                      @click="initialPayment.values[countryId].type = 'percentage'"
                    >
                      %
                    </v-btn>
                    <span class="mx-1"> / </span>
                    <v-btn
                      x-small
                      icon
                      elevation="3"
                      class="pa-3"
                      :class="{ primary: initialPayment.values[countryId].type === 'numeric' }"
                      @click="initialPayment.values[countryId].type = 'numeric'"
                    >
                      123
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="openDialogConfirmCreateFinancialProduct">Crear</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog confirm create product -->
    <DialogBox :model="dialogConfirmCreateFinancialProduct" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>Crear producto</span>
      </template>
      <template slot="content">
        <b>¿Estás seguro de crear el producto? </b>
        <span>Recuerda que los productos no podrán ser eliminados, únicamente se podrán habilitar/deshabilitar</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogConfirmCreateFinancialProduct = false">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import CommonMixin from "@/mixins/CommonMixin";
import Banner from "@/components/elements/Banner";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import ProductTags from "@/components/elements/products/ProductTags.vue";

export default {
  name: "Create-product",
  components: {
    Banner,
    DialogBox,
    DatePicker,
    ProductTags
  },
  mixins: [FormatDateMixin, FormRulesMixin, CommonMixin],
  data() {
    return {
      countryItems: [],
      countriesSelected: {},
      initialPayments: [],
      financialProductTypes: ["COOPERATIVE", "YUNUS", "NOMINAL"],
      currenciesOfAvailablesCountries: {},

      // Fields of financial product
      newFinancialProduct: {
        name: "",
        requirements: {
          offices: [],
          min_credits: 0
        },
        conditions: {
          arrear_coeficient: 0,
          monthly_fees: 0,
          waiting_period: 0,
          monthly_interest: 0,
          zero_interest_in_waiting_period: false,
          commission: 0,
          commission_real: 0
        },
        type: "",
        expiration_date: "",
        tags: []
      },
      formCreateFinancialProductValid: false,

      // Dialog confirm create financial product
      dialogConfirmCreateFinancialProduct: false
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.locations.countries,
      partners: (state) => state.partners.partners,
      offices: (state) => state.offices.offices
    }),
    _() {
      return _;
    },
    isSomeOfficeSelected() {
      let isSomeOfficeSelected = false;
      const selectedOffices = this._.values(this.countriesSelected);
      for (const office of selectedOffices) {
        if (office.length > 0) isSomeOfficeSelected = true;
      }
      return isSomeOfficeSelected;
    }
  },
  async created() {
    await this.$store.dispatch("locations/getCountries");
    await this.$store.dispatch("partners/getPartners");
    await this.$store.dispatch("offices/getOffices");
    await this.getCurrenciesOfAvailablesCountries();
    this.getCountryItems();
  },
  methods: {
    ...mapActions({
      createFinancialProduct: "products/createFinancialProduct"
    }),

    // Get array of partners and offices for treeview
    getCountryItems() {
      for (let i = 0; i < this.countries.length; i++) {
        const partners = this.partners.filter((partner) => partner.country.id === this.countries[i].id);
        const data = {
          id: this.countries[i].id,
          name: this.countries[i].name,
          disabled: this.isUncompleteCountry(this.countries[i]),
          children: []
        };

        for (let k = 0; k < partners.length; k++) {
          const partner = partners[k];
          const hasOffices = this.offices.some((e) => e.partner.id === partner.id);

          if (hasOffices) {
            data.children.push({
              id: partner.id,
              name: partner.name,
              children: []
            });

            for (const office of this.offices.filter((e) => e.partner.id === partner.id)) {
              const partnerChildrenPos = data.children.findIndex((partnerChildren) => partnerChildren.id === partner.id);
              data.children[partnerChildrenPos].children.push({
                id: office.id,
                name: office.name
              });
            }
          }
        }

        if (data.children.length > 0) this.countryItems.push(data);
      }
    },

    openDialogConfirmCreateFinancialProduct() {
      if (this.$refs.formCreateFinancialProduct.validate()) {
        if (this.formCreateFinancialProductValid && this.isSomeOfficeSelected) this.dialogConfirmCreateFinancialProduct = true;
      }
    },

    // Create financial product
    async create() {
      if (this.$refs.formCreateFinancialProduct.validate()) {
        let productFormattedData = { ...this.newFinancialProduct };
        const valuesOffices = [];

        for (let i = 0; i < this._.values(this.countriesSelected).length; i++) {
          const officesInCountry = this._.values(this.countriesSelected)[i];
          for (let j = 0; j < officesInCountry.length; j++) {
            const office = officesInCountry[j];
            valuesOffices.push(office);
          }
        }

        productFormattedData.requirements.offices = valuesOffices;
        if (productFormattedData.expiration_date) {
          productFormattedData.expiration_date = this.formatDateToISO8601(this.newFinancialProduct.expiration_date);
        } else delete productFormattedData.expiration_date;

        if (this.initialPayments.length > 0) {
          productFormattedData.initial_payments = {};

          for (let i = 0; i < this.initialPayments.length; i++) {
            const payment = { ...this.initialPayments[i] };
            productFormattedData.initial_payments[payment.id] = payment;
            delete productFormattedData.initial_payments[payment.id].id;
          }
        }
        console.log(productFormattedData);

        await this.createFinancialProduct(productFormattedData);
        this.$router.push("/productos");
      }
    },

    // Add initial payment to array of intialpayments
    addInitialPayment() {
      const id = this.makeid(20);
      const newInitialPayment = { id, name: "", values: {} };

      for (let i = 0; i < this._.values(this.countriesSelected).length; i++) {
        const offices = this._.values(this.countriesSelected)[i];

        if (offices.length > 0) {
          const countryId = this.offices.find((e) => e.id === offices[0]).country?.id;
          const currencyId = this.currenciesOfAvailablesCountries[countryId].id;

          newInitialPayment.values[currencyId] = { value: 0, type: "numeric" };
        }
      }

      this.initialPayments.push(newInitialPayment);
    },

    toogleCountry() {
      for (const countryId of this._.keys(this.countriesSelected)) {
        const currencyId = this.currenciesOfAvailablesCountries[countryId].id;

        if (this.countriesSelected[countryId].length > 0) {
          for (let i = 0; i < this.initialPayments.length; i++) {
            if (!this.initialPayments[i].values[currencyId]) {
              this.$set(this.initialPayments[i].values, currencyId, { value: 0, type: "numeric" });
            }
          }
        } else {
          for (let i = 0; i < this.initialPayments.length; i++) {
            delete this.initialPayments[i].values[currencyId];
          }
        }
      }

      if (!this.isSomeOfficeSelected) this.initialPayments = [];
    },

    async getCurrenciesOfAvailablesCountries() {
      for (let i = 0; i < this.partners.length; i++) {
        const partner = this.partners[i];
        const hasOffices = this.offices.some((e) => e.partner.id === partner.id);

        if (hasOffices && partner.country?.currency) {
          const currency = await this.$store.dispatch("currencies/getCurrency", { filter: { id: partner.country.currency } });
          this.currenciesOfAvailablesCountries[partner.country.id] = currency;
        }
      }
    },

    // Return if a country has not all necessary information
    isUncompleteCountry(country) {
      return !country.arrear_vat && !country.vat && !country.currency;
    }
  }
};
</script>
